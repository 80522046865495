<template>
    <el-dialog tabindex="-1" width="800px" top="40px" title="Granska balansräkning" :visible.sync="visible" :before-close="closeModal" :close-on-click-modal="false" @closed="resetModalState">
        <PdfCard :client-info="clientInfo" :data="balanceSheetData" :table-data="assetsData" :title="title" />
        <PdfCard :client-info="clientInfo" :data="balanceSheetData" :table-data="localEquityAndLiabilityData" :title="title" />
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal" type="default">Stäng</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        clientId: {
            type: String,
        },
        clientInfo: {
            type: Object,
        },
        balanceSheetData: {
            type: Object,
        },
        tableData: {
            type: Array,
        },
        equityAndLiabilityData: {
            type: Array,
        },
    },

    components: {
        PdfCard: () => import("../../components/FinancialPdfCard.vue"),
    },

    data() {
        return {
            formData: {},
            title: "Balansräkning",
        };
    },

    methods: {
        resetModalState() {
            this.formData = {};
        },

        closeModal() {
            this.$emit("close");
        },
        handlePeriodRounded(row) {
            return row.periodRounded !== 0 && row.type !== 1 ? -row.periodRounded : row.periodRounded;
        },
        handlePreviousPeriodRounded(row) {
            return row.previousPeriodRounded !== 0 && row.type !== 1 ? -row.previousPeriodRounded : row.previousPeriodRounded;
        },
        handleName(rowValue) {
            return rowValue === "Bundet eget kapital" || rowValue === "Fritt eget kapital";
        },
    },
    computed: {
        localEquityAndLiabilityData() {
            const data = this.equityAndLiabilityData
                .filter(row => row.balanceType === 1)
                .map(row => ({
                    ...row,
                    periodRounded: this.handleName(row.name) ? null : this.handlePeriodRounded(row),
                    previousPeriodRounded: this.handleName(row.name) ? null : this.handlePreviousPeriodRounded(row),
                }))
                .filter(row => (row.type == 4 && row.sruCode === "7301" && row.sruCode === "7302") || row.type !== 4 || this.handleName(row.originalRowName));
            return data;
        },
        assetsData() {
            return this.tableData.filter(row => row.balanceType === 0);
        },
    },
};
</script>
